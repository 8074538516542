"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocations = exports.getJsonBySearchId = exports.getJson = exports.getHtmlBySearchId = exports.getHtml = exports.getAccount = exports.MissingApiKeyError = exports.InvalidTimeoutError = exports.InvalidArgumentError = exports.config = void 0;
var config_js_1 = require("./src/config.js");
Object.defineProperty(exports, "config", { enumerable: true, get: function () { return config_js_1.config; } });
var errors_js_1 = require("./src/errors.js");
Object.defineProperty(exports, "InvalidArgumentError", { enumerable: true, get: function () { return errors_js_1.InvalidArgumentError; } });
Object.defineProperty(exports, "InvalidTimeoutError", { enumerable: true, get: function () { return errors_js_1.InvalidTimeoutError; } });
Object.defineProperty(exports, "MissingApiKeyError", { enumerable: true, get: function () { return errors_js_1.MissingApiKeyError; } });
var serpapi_js_1 = require("./src/serpapi.js");
Object.defineProperty(exports, "getAccount", { enumerable: true, get: function () { return serpapi_js_1.getAccount; } });
Object.defineProperty(exports, "getHtml", { enumerable: true, get: function () { return serpapi_js_1.getHtml; } });
Object.defineProperty(exports, "getHtmlBySearchId", { enumerable: true, get: function () { return serpapi_js_1.getHtmlBySearchId; } });
Object.defineProperty(exports, "getJson", { enumerable: true, get: function () { return serpapi_js_1.getJson; } });
Object.defineProperty(exports, "getJsonBySearchId", { enumerable: true, get: function () { return serpapi_js_1.getJsonBySearchId; } });
Object.defineProperty(exports, "getLocations", { enumerable: true, get: function () { return serpapi_js_1.getLocations; } });
